import React from "react"
import { ADD_MODE, useFormMode } from "../components/form"
import { AddSchedaLink, EditSchedaLink, ViewSchedaLink } from "../components/scheda"

const SchedaLinkPage = ({location}) => {
    const id = location.state?.id || null
    const mode = location.state?.mode || ADD_MODE
    
    const Add = () => <AddSchedaLink/>
    const Edit = () => <EditSchedaLink id={id}/>
    const View = () => <ViewSchedaLink id={id}/>

    const Form = useFormMode(
        Add,
        Edit,
        View,
        mode
    )

    return <Form/>
}

export default SchedaLinkPage
